/* requires:
polyfill.js
*/

// display the current year in the footer
if (document.querySelector('.copyright-year')) {
  document.querySelector('.copyright-year').innerHTML = new Date().getFullYear();
}

// florida-traffic-school-eligibility ticket years
if (document.querySelector('#current-year')) {
  document.querySelector('#current-year').innerHTML = new Date().getFullYear();
}
if (document.querySelector('#next-year')) {
  document.querySelector('#next-year').innerHTML = new Date().getFullYear() + 1;
}